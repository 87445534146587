<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.href" class="text-subtitle-2 crumb-item" :disabled="item.disabled"
                                exact>
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color"
                        :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout"
                        :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                            <v-layout column>
                                <div>
                                    <strong>{{ snackbar.title }}</strong>
                                </div>
                                <div>{{ snackbar.text }}</div>
                            </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                            <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>

            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Label by </h6>
                                <v-autocomplete 
                                    dense
                                    solo class="ma-0 pa-1 border-12" hide-details=true 
                                    v-model="label"
                                    :items="labels" 
                                    default="" 
                                    item-value="label" 
                                    item-text="label" 
                                    label="Label by">
                                    <!-- @change="(event) => getFilter(event)" > -->
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                
                                <h6 class="blue-lcd mb-1 pa-1">Region <strong style="color:red;">*</strong><span></span> </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="region"
                                    :items="regions" default="" item-value="region" item-text="region" label="Region"
                                    @change="(event) => getOfficeID(event)"  clearable>
                                    <!-- :disabled="disabledRegion" -->
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Branch <strong style="color:red;">*</strong><span></span> </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="office"
                                    :items="offices" default="" item-value="office_id" item-text="office" label="Branch"  clearable>
                                    <!-- :disabled="disabledOffice" -->
                                </v-autocomplete>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Category Group </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="categoryGroup"
                                    :items="categoryGroups" default="" item-value="cat_group" item-text="cat_group"
                                    label="Category Group" @change="(event) => getCategory(event)"
                                    :disabled="disabledCategoryGroup" clearable>
                                </v-autocomplete>
                            </v-col> -->
                            
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Category </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="category"
                                    :items="categories" default="" item-value="category_id" item-text="cat_desc"
                                    label="Category"  clearable @change="(event) => getFinishGood(event)">
                                    <!-- :disabled="disabledCategory" -->
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Fast Moving </h6>
                                <v-autocomplete 
                                    dense
                                    solo class="ma-0 pa-1 border-12" hide-details=true 
                                    v-model="labelFM"
                                    :items="labelFMs" 
                                    default="" 
                                    item-value="label" 
                                    item-text="label" 
                                    label="Fast Moving"
                                    @change="(event) => getFinishGood(event)" >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Barang Jadi </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="finishGood"
                                    :items="finishGoods" default="" item-value="barangjadiid" item-text="namabarang"
                                    label="Barang Jadi" clearable>
                                    <!-- :disabled="disabledCategory" -->
                                </v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row v-if="label === 'date'">
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">From <strong style="color:red;">*</strong><span></span> </h6>
                                <v-menu 
                                    ref="modal_from" 
                                    v-model="modal_from" :close-on-content-click="false"
                                    transition="scale-transition" 
                                    offset-y max-width="290" 
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        
                                        <v-text-field solo 
                                            dense
                                            clearable
                                            v-model="date_from" 
                                            label="Start Date" 
                                            persistent-hint
                                            append-icon="mdi-calendar" 
                                            v-bind="attrs" 
                                            v-on="on" 
                                            class="ma-0 pa-0 mb-1 border-12"
                                            hide-details=true></v-text-field>
                                    </template>
                                    
                                    <v-date-picker type="date" v-model="date_from" no-title @input="modal_from = false"></v-date-picker>
                                </v-menu>
                                
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">To <strong style="color:red;">*</strong><span></span> </h6>
                                <v-menu 
                                    ref="modal_to" 
                                    v-model="modal_to" :close-on-content-click="false"
                                    transition="scale-transition" 
                                    offset-y max-width="320px" 
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field solo 
                                            dense
                                            clearable
                                            v-model="date_to" 
                                            label="End Date" 
                                            persistent-hint
                                            append-icon="mdi-calendar" 
                                            v-bind="attrs" 
                                            v-on="on" 
                                            class="ma-0 pa-0 mb-1 border-12"
                                            hide-details=true></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_to" no-title @input="modal_to = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-8 border-12" style="padding: 19px;" small color="primary" elevation="2" @click="getPullData()">Search</v-btn>
                            </v-col>
                        </v-row>

                        <v-row v-else-if="label === 'month'">
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Start Period <strong style="color:red;">*</strong><span></span> </h6>
                                <v-menu ref="modal" 
                                v-model="modal" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        solo 
                                        v-model="period" 
                                        label="Period" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period" 
                                        type="month" 
                                        no-title @input="modal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">To Period <strong style="color:red;">*</strong><span></span> </h6>
                                <v-menu ref="modal_to_period" 
                                v-model="modal_to_period" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        solo 
                                        v-model="period_to" 
                                        label="To Period" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period_to" 
                                        type="month" 
                                        no-title @input="modal_to_period = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-8 border-12" style="padding: 19px;" small color="primary" elevation="2" @click="getPullData()">Search</v-btn>
                            </v-col>
                        </v-row>

                        <v-row v-else>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Start Year <strong style="color:red;">*</strong><span></span> </h6>
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="320px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            dense
                                            v-model="year"
                                            label="Year"
                                            append-icon="event"
                                            v-bind="attrs"
                                            v-on="on"
                                            solo
                                            class="ma-0 border-12"
                                            readonly
                                            hide-details=true
                                        >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        ref="picker"
                                        v-model="date"
                                        :max="picker"
                                        @input="save"
                                        reactive
                                        no-title>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">To Year <strong style="color:red;">*</strong><span></span> </h6>
                                <v-menu
                                    ref="menu_to_year"
                                    v-model="menu_to_year"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="320px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            dense
                                            v-model="year_to"
                                            label="To Year"
                                            append-icon="event"
                                            v-bind="attrs"
                                            v-on="on"
                                            solo
                                            class="ma-0 border-12"
                                            readonly
                                            hide-details=true
                                        >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        ref="picker_to"
                                        v-model="date_to_year"
                                        :max="picker"
                                        @input="save_date_to_year"
                                        reactive
                                        no-title>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-8 border-12" style="padding: 19px;" small color="primary" elevation="2" @click="getPullData()">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mt-5">
            <v-col cols="12">
                <v-card outlined class="pa-md-5">  
                    <h6 class="cyan-text text-darken-3 m-0">Stock Occupancy</h6>
                    <div id="container1" style="height: 370px; width: 100%;"></div>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogDetail" max-width="80%">
            <div class="card elevation-5">
                <div class="card-body">
                    <h5 class="card-title">DATE : {{ dateLabel }}</h5>
                    <v-data-table :headers="headersDetails" :items="itemDetailLists" class="elevation-1" page-count="5" :loading="loadingDatatableDetail" dense>
                    </v-data-table>
                </div>
            </div>
        </v-dialog>

    </v-container>
</template>

<script>
import {master} from "../../../backend-api/kmb/master"
import {occupancy} from "../../../backend-api/kmb/occupancy"
import {backendApi} from "../../../backend-api/backend-api-sr"

export default {
    data(){
        return{
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search: '',
            breadcumbs: [
                {
                    text: 'KMB',
                    disabled: false,
                    href: '/admin/kmb',
                },
                {
                    text: 'Occupancy',
                    disabled: true,
                    href: '/admin/kmb',
                },
                {
                    text: 'Stock Occupancy',
                    disabled: true
                }
            ],
            label: 'date',
            labels: [
                {label:'date'},
                {label:'month'},
                {label:'year'},
            ],
            region: '',
            regions: [],
            disabledRegion: false,
            office: '',
            offices: [],
            disabledOffice: false,
            labelFM: 'All',
            labelFMs: [
                {label:'All'},
                {label:'Yes'},
                {label:'No'},
            ],
            categoryGroup: '',
            categoryGroups: [],
            disabledCategoryGroup: false,
            category: '',
            categories: [],
            disabledCategory: false,
            finishGood: '',
            finishGoods: [],
            date_from:'',
            modal_from:false,
            date_to:'',
            modal_to:false,
            modal: false,
            period: '',
            menu: false,
            year: '',
            picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date:'',
            modal_to_period: false,
            period_to: '',
            menu_to_year: false,
            year_to: '',
            date_to_year: '',
            x: window.matchMedia("(max-width: 991px)"),
            dialogDetail: false,
            dateFormatted: '',
            itemDetailLists: [],
            headersDetails: [
                { text: 'ITEM', value: 'namabarang', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'PROD. CODE', value: 'barangjadiid', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'THICK.', value: 'tebal', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'LENGTH', value: 'length', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'MIN. STOCK', value: 'min_qty', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'AVAILABLE', value: 'end_qty', align: 'right', class: 'primary--text blue lighten-5' },
            ],
            loadingDatatableDetail: false,
            dateLabel: ''
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', false)
        await this.getRegion()
        await this.getCategory('All')
        this.$refs.picker.activePicker = 'YEAR'    
        this.$refs.picker_to.activePicker = 'YEAR'
        
    },
    methods: {
        save_date_to_year(date) {
            console.log(date);

            this.$refs.menu_to_year.save(date)

            this.$refs.picker_to.activePicker = 'YEAR'

            this.menu_to_year = false

            this.year_to = new Date(date.substr(0, 4)).toISOString().substr(0, 4)
        },
        save(date) {
            console.log(date);

            this.$refs.menu.save(date)

            this.$refs.picker.activePicker = 'YEAR'

            this.menu = false

            this.year = new Date(date.substr(0, 4)).toISOString().substr(0, 4)
        },

        getFilter(){},

        async getRegion(event){
            const respData = await master.fetchRegion('', null, false, false, false)
            if (respData.status === 200) {
                this.regions = respData.data.data
            }
        },
        async getOfficeID(event){
            if (event === '' || event === null) {
                this.office = ''
            } else {
                // var reqBody = {
                //     'regionid': this.region
                // }
                const respData = await master.fetchOffice(`?region=${this.region}`, null, false, false, false)
                if (respData.status === 200) {
                    this.offices = respData.data.data
                }
            }
        },
        async getCategory(event){
            const respData = await master.fetchCategory(``, null, false, false, false)
            if (respData.status === 200) {
                this.categories = respData.data.data
            }
        },
        async getFinishGood(event){
            if (event === '' || event === null) {
                this.office = ''
            } else {
                var fast = ''
                if(this.labelFM == "Yes"){
                    fast = "Y"
                }
                if(this.labelFM == "No"){
                    fast = "N"
                }
                // var reqBody = {
                //     'regionid': this.region
                // }
                const respData = await master.fetchFinishGoodsFastMove(`?category_id=${this.category}&fast_moving=${fast}`, null, false, false, false)
                if (respData.status === 200) {
                    // console.log(respData.data.data)
                    this.finishGoods = respData.data.data
                }
            }
        },
        async getPullData(){
            this.$store.dispatch('setOverlay', true)
            var periodStart = ''
            var periodEnd = ''
            var container = "container1";
            var fast = ''
            if(this.labelFM == "Yes"){
                fast = "Y"
            }
            if(this.labelFM == "No"){
                fast = "N"
            }

            if (this.label === 'date') {
                periodStart = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10) : ''
                periodEnd = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10) : ''
            } else if(this.label === 'month'){
                periodStart = this.period ? new Date(this.period).toISOString().substr(0, 8).replace(/-/g,"") : ''
                periodEnd = this.period_to ? new Date(this.period_to).toISOString().substr(0, 8).replace(/-/g,"") : ''
            } else {
                periodStart = this.year ? new Date(this.year).toISOString().substr(0, 4).replace(/-/g,"") : ''
                periodEnd = this.year_to ? new Date(this.year_to).toISOString().substr(0, 4).replace(/-/g,"") : ''
            }
            var respData = await occupancy.stockOccupancy(`?period=${this.label ? this.label : 'month'}&region=${this.region ? this.region : ''}&office_id=${this.office ? this.office : ''}&category_id=${this.category ? this.category : ''}&fast_move=${fast ? fast : ''}&barangjadiid=${this.finishGood ? this.finishGood : ''}&period_start=${periodStart ? periodStart : ''}&period_end=${periodEnd ? periodEnd : ''}`, null, false, false, false)
            
            if (respData.status === 200) {
                var inputJson = respData.data.data
                // Transforming into [{ "label": "202307", "y": "3396.1092" }, { "label": "202308", "y": "3396.1092" }]
                var transformedWGT_CAP = inputJson.map(({ period, WGT_CAP }) => ({ label: period, y: parseFloat(WGT_CAP) }));

                // Transforming into [{ "label": "202307", "y": "2507.9997" }, { "label": "202308", "y": "4757.2482" }]
                var transformedWgtEnd = inputJson.map(({ period, wgt_end }) => ({ label: period, y: parseFloat(wgt_end) }));

            
                await this.getChart(transformedWgtEnd, transformedWGT_CAP, "Current Cap", "Min Cap", container, 'line');
                this.$store.dispatch('setOverlay', false)
            } else {
                console.log(respData.data);
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: respData.data.data,
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)

                await this.getChart([], [], "Current Cap", "Min Cap", container, 'line');

                return false
            }
        },
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        getChart(dt1, dt2, name1, name2, container, type){
            // console.log(dt1)
            // console.log(dt2)
            // console.log(name1)
            // console.log(name2)
            // console.log(container)
            // console.log(type)
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: {
                    title: "PCS",
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true,
                    yValueFormatString: "##0.00",
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                {
                    type: type,
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#FFF",
                    name: name1,
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    color: "#2d4059",
                    yValueFormatString: "##0.00",
                    click: this.getDetails,
                    // yValueFormatString: "#,###,,,.##",
                },
                {
                    type: type,
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#FFF",
                    name: name2,
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    // color: "#2d4059",
                    color: "#ed6663",
                    yValueFormatString: "##0.00",
                    click: this.getDetails,
                    // yValueFormatString: "#,###,,,.##",
                }]
            });

            chart.options.data[0].dataPoints = dt1;
            chart.options.data[1].dataPoints = dt2;

            if (this.x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },

        async getDetails(e){

            this.dialogDetail = true
            this.itemDetailLists = []
            this.loadingDatatableDetail = true

            this.dateFormatted = new Date(e.dataPoint.label).toISOString().substr(0, 10).replace(/-/g,"")

            await axios.post(`${process.env.VUE_APP_URL}/v2/kmb/occupancy/stock/getDetail`, { 

                    region: this.region ? this.region : '',
                    office: this.office ? this.office : '',
                    category: this.category ? this.category : '',
                    fastMoving: this.labelFM ? this.labelFM : '',
                    productCode: this.finishGood ? this.finishGood : '',
                    date: this.dateFormatted,
                    dateLabel: e.dataPoint.label

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.loadingDatatableDetail = false
                this.itemDetailLists = res.data.result
                this.dateLabel = res.data.dateLabel
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err);

            })

        }
    },
    watch: {
        menu (val) {
            console.log(val);
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
        menu_to_year (val) {
            console.log(val);
            val && setTimeout(() => (this.$refs.picker_to.activePicker = 'YEAR'))
        },
    },
}
</script>