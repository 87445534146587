import {backendApi} from "../backend-api-sr"

export const occupancy = (() =>{
    const stockOccupancy = ( async (subPath) => {
        var url = '/v2/kmb/occupancy/stock/GetData'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    return {stockOccupancy};
})()